/**
* Generated automatically at built-time (2024-10-07T17:11:42.147Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "custom-made-abdeckung-outdoorkueche",templateKey: "sites/105-b40ec4e6-84a8-4c93-915b-ac47c3403187"};