/**
* Generated automatically at built-time (2024-10-07T17:11:42.016Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "3d-planung-outdoorkueche",templateKey: "sites/105-769674c0-b803-4129-ab4f-82ae3ed029ea"};